<template>
  <el-row>
    <el-col class="container" style="margin-bottom: 3.4375rem;">
      <el-image
        fit="contain"
        :src="data.imgUrls"
        :preview-src-list="imgUrlList"
        style="width: 100%; height: 100%;"
        @click.stop="handleClickStop"
      ></el-image>

      <div class="flex flex-direction info-detail">
        <div class="text-bold" style="font-size: 19px; line-height: 1.75rem;">
          {{ data.title.length ? data.title.substring(0, 36) : data.title }}
        </div>

        <div class="flex align-center price">
          <template v-if="!data.ticketNum">
            <div v-if="!data.price || data.price == 0">
              免费
            </div>
            <div v-else>
              <span style="font-size: 13px;">￥</span>
              <span style="font-size: 16px;">{{ data.price / 100 }}</span>
            </div>
          </template>

          <template v-else>
            <div v-if="!data.price || data.price == 0">
              免费
            </div>
            <div v-else>
              <span style="font-size: 13px;">￥</span>
              <span style="font-size: 16px;"
                >{{ data.price / 100 }}-{{ data.maxPrice / 100 }}</span
              >
            </div>
          </template>
        </div>

        <div class="flex align-center">
          <i class="el-icon-time" style="font-size: 14px; color: #959595"></i>
          <div style="padding-left: 0.5rem; font-size: 14px; color: #959595">
            {{ startTime(data.startTime, data.endTime) }}
          </div>
        </div>

        <div class="flex align-center">
          <i
            class="el-icon-location-outline"
            style="font-size: 14px; color: #959595"
          ></i>

          <div
            style="flex: 1; padding-left: 0.5rem; font-size: 14px; color: #959595"
          >
            {{ cityLinkAddressStr }}
          </div>

          <div
            class="flex align-center"
            style="flex-wrap: nowrap; margin-left: .6875rem; width: 3.5rem; cursor: pointer;"
            @click.stop="navigation(cityLinkAddressStr)"
          >
            <el-image
              fill="contain"
              :src="require('@/assets/img/common/navigation.png')"
              style="width: 1rem;height: 1rem;"
            ></el-image>
            <span class="text-24" style="margin-left: .25rem;color: #0b4bf7;"
              >导航</span
            >
          </div>
        </div>
      </div>

      <div class="margin-top-xl activity-details">
        <div class="title">活动详情</div>
        <div
          class="context"
          v-html="data.content"
          @click.stop="previewImage($event)"
        ></div>

        <!-- 富文本中图片放大预览部分 -->
        <el-image-viewer
          v-if="dialogVisible"
          :on-close="closeImage"
          :url-list="[previewImgList]"
        />
      </div>

      <div class="flex align-center footer">
        <div class="flex align-center funcbtn">
          <div
            class="flex-sub flex flex-direction align-center btn"
            @click.stop="share"
          >
            <el-image
              fill="contain"
              :src="require('@/assets/img/common/share.png')"
              style="width: 1.75rem;height: 1.75rem;"
            ></el-image>
            <span>分享</span>
          </div>

          <el-popover
            placement="bottom"
            title="请扫码在小程序内进行操作"
            width="200"
            trigger="click"
          >
            <el-image
              fill="contain"
              :src="require('@/assets/img/common/qrcode.jpg')"
              style="width: 10.9375rem;height: 10.9375rem;"
            ></el-image>
            <div
              slot="reference"
              class="flex-sub flex flex-direction align-center btn"
              style="margin-top: 0.1rem;"
            >
              <el-image
                fill="contain"
                :src="require('@/assets/img/common/customerService.png')"
                style="width: 1.5rem;height: 1.5rem;"
              ></el-image>
              <span>客服</span>
            </div>
          </el-popover>
        </div>

        <div class="flex-sub">
          <el-popover
            v-if="data.activityStatus == 0"
            placement="bottom"
            title="请扫码在小程序内进行操作"
            width="200"
            trigger="click"
          >
            <el-image
              fill="contain"
              :src="require('@/assets/img/common/qrcode.jpg')"
              style="width: 10.9375rem;height: 10.9375rem;"
            ></el-image>
            <el-button slot="reference" class="sign" hover-class="sign__hover">
              立即报名
            </el-button>
          </el-popover>
          <el-button v-else class="sign sign-end" disabled>
            <span v-if="data.activityStatus == -1">活动未开始</span>
            <span v-if="data.activityStatus == 1">报名已截止</span>
            <span v-else-if="data.activityStatus == 2">活动已结束</span>
            <span v-else-if="data.activityStatus == 3">已售罄</span>
          </el-button>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { request } from "@/utils/request";

export default {
  name: "latestActivityDetail",
  components: {
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer")
  },
  data() {
    return {
      // 进入方式是否为扫码
      isQRcode: false,
      // 当前页面的url地址
      url: window.location.href,
      // 是否正在加载
      isLoading: false,

      data: {
        activityStatus: 0,
        address: "",
        content: "",
        endTime: "0000-00-00 00:00:00s",
        id: 1,
        imgUrls: "",
        price: 0,
        publicityUrl: "",
        startTime: "0000-00-00 00:00:00",
        title: "",
        ticketNum: 0,
        province: "",
        city: "",
        area: ""
      },

      // 顶部图片展示
      imgUrlList: [],

      // 预览图片路径
      dialogVisible: false,
      previewImgList: ""
    };
  },
  computed: {
    // 地址拼接
    cityLinkAddressStr() {
      let provinceStr = "";
      let cityStr = "";
      let areaStr = "";
      if (this.data.province) {
        provinceStr = this.data.province.split(",")[1]
          ? this.data.province.split(",")[1]
          : "";
      }
      if (this.data.city) {
        cityStr = this.data.city.split(",")[1]
          ? this.data.city.split(",")[1]
          : "";
      }
      if (this.data.area) {
        areaStr = this.data.area.split(",")[1]
          ? this.data.area.split(",")[1]
          : "";
      }
      return provinceStr + cityStr + areaStr + this.data.address;
    },

    // 计算最新的开始时间
    startTime() {
      return function(start, end) {
        let sDT = start.split(" ");
        let eDT = end.split(" ");
        if (sDT[0] == eDT[0]) {
          let sTime = sDT[1].split(":");
          let eTime = eDT[1].split(":");
          return (
            sDT[0] +
            " " +
            sTime[0] +
            ":" +
            sTime[1] +
            "-" +
            eTime[0] +
            ":" +
            eTime[1]
          );
        } else {
          let eDate = eDT[0].split("-");
          return sDT[0] + " 至 " + eDate[1] + "-" + eDate[2];
        }
      };
    }
  },
  beforeMount() {
    // console.log(this.$route.query);
    this.data.id = this.$route.query.id;
    this.request();
  },
  mounted() {
    this.request();
  },
  methods: {
    // 顶部图片预览关闭
    handleClickStop() {
      this.$nextTick(() => {
        let domImageView = document.querySelector(".el-image-viewer__mask"); // 获取遮罩层dom
        if (!domImageView) {
          return;
        }
        domImageView.addEventListener("click", () => {
          // 点击遮罩层时调用关闭按钮的 click 事件
          document.querySelector(".el-image-viewer__close").click();
        });
      });
    },

    // 图片预览
    previewImage(e) {
      if (e.target.tagName == "IMG") {
        // console.log(e.target.src);
        this.previewImgList = e.target.src;
        this.dialogVisible = true;
      }
    },

    closeImage() {
      this.dialogVisible = false;
      this.previewImgList = "";
    },

    // 请求
    request() {
      let url =
        "https://xinke.thinkervc.cn/api/applet/activityInfo/getActivityDetailInfoById?id=" +
        this.data.id;

      request(url, {})
        .then(res => {
          if (!this.isLoading) this.isLoading = true;
          if (res.code == 200) {
            this.data = res.data;

            // console.log("获取活动详细信息");
            // console.log(res.data);

            // 重设标题
            // document.title =
            //   this.data.title.length > 10
            //     ? this.data.title.substring(0, 10) + "..."
            //     : this.data.title;

            // 设置图片预览列表
            this.imgUrlList = this.data.imgUrls.split(",");
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    // 点击复制
    share() {
      var input = document.createElement("input"); // 创建input对象
      input.value = this.url; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("链接复制成功！请在聊天工具内黏贴分享给其他用户！");
    },

    // 跳转到地图导航页
    navigation(address) {
      this.$router.push({ name: "Map", query: { address } });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-image-viewer__close {
  color: #fff;
  background-color: rgba($color: #997e7e, $alpha: 1);
  box-shadow: 0px 0px 0px 4px rgba($color: #fff, $alpha: 0.75);
}

.container {
  background-color: #fff;

  .info-detail {
    margin-top: -1rem;
    width: 100%;
    height: auto;
    padding: 5%;
    border-radius: 0.6875rem;
    background: #fff;
    z-index: 1;

    & > div {
      margin-bottom: 0.5rem;
    }

    .price {
      margin-right: 3%;
      height: 1.25rem;
      line-height: 1.25rem;
      font-weight: bold;
      color: #f08519;

      & > div {
        font-size: 16px;
        color: #ff4e00;
      }
    }
  }

  .activity-details {
    padding: 5%;
    padding-bottom: 3%;

    .title {
      font-weight: bold;
      margin-bottom: 1rem;
    }

    .context {
      text-align: justify;
      text-justify: newspaper;
      word-break: break-all;
      overflow: hidden;
    }

    ::v-deep img {
      width: 100%;
    }
  }

  .footer {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3.4375rem;
    border-top: 1px solid #f3f3f3;
    background-color: #fff;
    z-index: 100;

    .funcbtn {
      flex-wrap: nowrap;
      padding-left: 2%;
      width: 8.125rem;
      height: 100%;

      .btn {
        padding-top: 0.5rem;
        width: 3.875rem;
        height: 100%;
        background-color: #fff;
        cursor: pointer;
      }

      span {
        font-size: 12px;
      }
    }

    .sign {
      width: 94%;
      height: 2.5rem;
      color: #fff;
      background-image: linear-gradient(180deg, #8f8fff, #3e3ce9);
      border-radius: 1.125rem;
    }

    .sign__hover {
      position: relative;
      top: 0.0938rem;
      left: 0.0938rem;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1) inset;
    }

    .sign-end {
      background-image: linear-gradient(180deg, #d2d1d1, #a2a2a2);
    }
  }
}
</style>
